import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import customerEditReducer from './customerEditReducer';
import authenticatedUserReducer from './authenticatedUserReducer';
import companyWareHouseListReducer from './companyWareHouseListReducer';
import shipmentsDataReducer from './shipmentsDataReducer';
import userDataReducer from './userDataReducer';
import dashboardShipmentsDataReducer from './dashboardShipmentsDataReducer';
import contactDataReducer from './contactDataReducer';
import shipmentReportReducer from './shipmentReportReducer';
import shipmentSummaryReducer from './shipmentSummaryReducer';
import invoiceDataReducer from './invoicesDataReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'berry-'
    },
    cartReducer
  ),
  customer: customerEditReducer,
  user: authenticatedUserReducer,
  shipmentsData: shipmentsDataReducer,
  dashboardShipmentsData: dashboardShipmentsDataReducer,
  userData: userDataReducer,
  company: companyWareHouseListReducer,
  contactData: contactDataReducer,
  shipmentReport: shipmentReportReducer,
  shipmentSummary: shipmentSummaryReducer,
  invoiceData: invoiceDataReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};
export default rootReducer;
