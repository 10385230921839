import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { fetchShipmentList } from 'containers/Shipments/action';
import * as actionTypes from '../../../store/actions';
import { showErrorToaster } from 'ui-component/alertService';
// import { getFormattedDate } from 'utils/getFormattedDate';
import usePermission from 'hooks/usePermission';

function createData(
  id,
  customer_id,
  customer_order_number,
  pickupData,
  name,
  address,
  city,
  state,
  zip,
  carrier,
  carrier_order_id,
  price,
  cost,
  date,
  delivery_date,
  status,
  primaryKey,
  sortKey,
  shipment_id,
  creation_timestamp,
  status_primary_key,
  packages,
  creation_datetime,
  creation_timezone
) {
  return {
    id,
    customer_id,
    customer_order_number,
    pickupData,
    name,
    address,
    city,
    state,
    zip,
    carrier,
    carrier_order_id,
    price,
    cost,
    date,
    delivery_date,
    status,
    primaryKey,
    sortKey,
    shipment_id,
    creation_timestamp,
    status_primary_key,
    packages,
    creation_datetime,
    creation_timezone
  };
}
let rowsInitial = [];
const useTableData = () => {
  const dispatch = useDispatch();
  const [hasPermission] = usePermission();
  const [isLoading, setIsLoading] = useState(false);
  const [statusType, setStatusType] = useState([]);

  const [shipmentType, setShipmentType] = useState([]);
  const [filterByCompany, setFilterByCompany] = useState('');
  const [filterByCarrier, setFilterByCarrier] = useState('');
  const [filterByWareHouse, setFilterByWareHouse] = useState('');

  const [dateRange, setDateRange] = useState([null, null]);
  const shipmentsData = useSelector(state => state?.shipmentsData);
  const rowsPerPage = shipmentsData.rowsPerPage;
  const page = shipmentsData.page;
  const pageKeys = shipmentsData.page_keys;
  const params = useSelector(state => state?.shipmentsData?.params);
  const userData = useSelector(state => state?.user?.userData);
  const initialShipmentRows = shipmentsData.shipmentList;

  const handleParams = (isFilterClear, tempParam) => {
    let currentParam = { ...tempParam };
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    if (!tempParam.isOutSide) {
      // if clear filter from chip then we don't need to update params
      if (statusType?.length > 0 && !isFilterClear) {
        currentParam.status = statusType;
      }
      if (shipmentType.length > 0 && !isFilterClear) {
        currentParam.shipmentType = shipmentType;
        if (shipmentType.length === 1) {
          currentParam.shipmentType = shipmentType[0];
        }
      }
    }
    let dateOffset = 24 * 60 * 60 * 1000 * 30; // 30 days
    let start = new Date();
    start.setTime(start.getTime() - dateOffset);
    start.setUTCHours(0, 0, 0, 0);

    let end = new Date();
    end.setUTCHours(23, 59, 59, 999);
    if (startDate) {
      currentParam.start_date = start.toISOString();
    }
    if (endDate) {
      currentParam.end_date = end.toISOString();
    }
    if (filterByCarrier && !isFilterClear) {
      currentParam.carrier = filterByCarrier;
    }
    if (filterByWareHouse && !isFilterClear) {
      currentParam.warehouse_id = filterByWareHouse;
    }
    if (filterByCompany && !isFilterClear) {
      currentParam.company_id = filterByCompany;
    }
    return currentParam;
  };
  const getShipmentList = async (
    page_,
    isfilter = false,
    isFilterClear = false,
    warehouse_id,
    tempParam,
    smoothScrollApiCall,
    paginationPage
  ) => {
    dispatch({
      type: actionTypes.LOADER,
      payload: true
    });
    const _params = handleParams(isFilterClear, { ...tempParam });
    if (pageKeys && !isfilter) {
      _params.pageKeys = pageKeys;
    }
    if (page_) {
      _params.page = page_;
    }
    if (warehouse_id) {
      _params.warehouse_id = warehouse_id;
    }
    // if user is not admin then it will pass their company_id, so it can get shipments related to the company
    const { id, company_name } = _params.companyDetails || userData?.companyDetails || {};
    if (!company_name) return;
    if (!(hasPermission('allCompanyData') && company_name?.toLowerCase() === 'shipx')) {
      _params.userCompanyId = id;
    }
    if (company_name?.toLowerCase() === 'shipx') {
      delete _params.userCompanyId;
    }
    // If searching shipment we don't need to pass dateRange
    _params.rowsPerPage = 500;
    delete _params.isOutSide;
    delete _params.companyDetails;

    if (Array.isArray(_params.shipmentType)) {
      delete _params.shipmentType;
    }
    _params['desired_fields'] = [
      'shipment_id',
      'company_id',
      'customer_order_number',
      'pickup',
      'delivery',
      'ship_to',
      'ship_from',
      'carrier_orders',
      'price',
      'ship_timestamp',
      'cost',
      'status_primary_key',
      'status_sort_key',
      'estimated_delivery_timestamp',
      'primaryKey',
      'sortKey',
      'creation_datetime',
      'creation_timezone'
    ];
    return fetchShipmentList(_params)
      .then(res => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST_PAGE_KEYS,
            payload: res?.data?.page_keys || []
          });
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST_LAST_PAGE,
            payload: res?.data?.is_last_page
          });
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST,
            payload: res?.data?.items
          });
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST_PARAMS,
            payload: _params
          });
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST_START_AND_END_DATE,
            payload: [_params.start_date, _params.end_date]
          });
          if (!smoothScrollApiCall) {
            // split shipment data based on row per page in screen
            let previousShipmentData = [...res?.data?.items];
            const arrPart = previousShipmentData.length / rowsPerPage;
            previousShipmentData = splitToChunks(previousShipmentData, arrPart);
            filterShipmentFieldForTable(previousShipmentData[0]);
          }
          dispatch({
            type: actionTypes.SET_SHIPMENT_LIST_PAGE_NUMBER,
            payload: paginationPage ? paginationPage : page_
          });
          dispatch({
            type: actionTypes.LOADER,
            payload: false
          });
          return res.data;
        }
      })
      .catch(e => {
        dispatch({
          type: actionTypes.LOADER,
          payload: false
        });
        showErrorToaster(e, 'something is wrong.');
      });
  };

  const filterShipmentFieldForTable = itemArr => {
    const filteredFieldArr = [];
    if (itemArr?.length) {
      map(itemArr, item => {
        filteredFieldArr.push(
          createData(
            item?.shipment_id,
            item?.company_id,
            item?.customer_order_number,
            item?.pickup || item?.ship_from,
            item?.delivery?.name || item?.ship_to?.name,
            item?.delivery?.address_line1 || item?.ship_to?.address_line1,
            item?.delivery?.city_locality || item?.ship_to?.city_locality,
            item?.delivery?.state_province || item?.ship_to?.state_province,
            item?.delivery?.postal_code || item?.ship_to?.postal_code,
            item?.carrier_orders && item?.carrier_orders[0]?.carrier,
            item?.carrier_orders && item?.carrier_orders[0]?.id,
            item?.price,
            parseFloat(item?.cost)?.toFixed(2),
            item?.ship_timestamp,
            item?.status_primary_key === 'Delivered' || item?.status_primary_key === 'Voided'
              ? item?.status_sort_key
              : item?.estimated_delivery_timestamp,
            item?.status,
            item?.primaryKey,
            item?.sortKey,
            item?.shipment_id,
            item?.creation_timestamp,
            item?.status_primary_key,
            item?.packages,
            item?.creation_datetime,
            item?.creation_timezone
          )
        );
      });
    }

    rowsInitial = [...filteredFieldArr];
    dispatch({
      type: actionTypes.SET_SHIPMENT_ROW,
      payload: filteredFieldArr
    });
  };

  const setCompanyDetails = (payload, type = 'selectedCompany') => {
    dispatch({ type, payload });
  };
  const isDataFetchedAlready = newPage => {
    return initialShipmentRows.length / (newPage + 1);
  };
  function splitToChunks(array, parts) {
    const result = [];
    for (let i = parts; i > 0; i--) {
      result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  }
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: actionTypes.SET_SHIPMENT_LIST_PAGE_NUMBER,
      payload: newPage
    });
    // It checks if we have already fetched limited data. If yes then don't call API for getting shipment list.
    if (isDataFetchedAlready(newPage) >= rowsPerPage) {
      let previousShipmentData = [...initialShipmentRows];
      // It will part shipment list data into array As per total row per page.
      // Ex: If rowPerPage is 10 and total data are 30 then it will part into 3 array
      const arrPart = previousShipmentData.length / rowsPerPage;
      previousShipmentData = splitToChunks(previousShipmentData, arrPart);
      filterShipmentFieldForTable(previousShipmentData[newPage]);
    } else {
      getShipmentList(newPage, false, false, '', params);
    }
  };
  function defaultLabelDisplayedRows({ from, to }) {
    // return `${111}–${11} of ${count !== -1 ? count : `more than ${11}`}`
    return `Shipments: ${from}-${to}`;
  }
  const handleChangeRowsPerPage = event => {
    if (event?.target.value)
      dispatch({
        type: actionTypes.SET_SHIPMENT_LIST_ROW_LENGTH,
        payload: parseInt(event?.target.value, 10)
      });
    setIsLoading(true);
    dispatch({ type: actionTypes.SET_SHIPMENT_LIST_PAGE_NUMBER, payload: 0 });
  };
  return {
    getShipmentList,
    filterShipmentFieldForTable,
    setIsLoading,
    isLoading,
    page,
    rowsPerPage,
    setFilterByCompany,
    setFilterByCarrier,
    setStatusType,
    setShipmentType,
    setDateRange,
    dateRange,
    statusType,
    shipmentType,
    filterByCompany,
    filterByCarrier,
    filterByWareHouse,
    setFilterByWareHouse,
    rowsInitial,
    setCompanyDetails,
    handleChangePage,
    defaultLabelDisplayedRows,
    handleChangeRowsPerPage,
    shipmentsData,
    createData
  };
};

export default useTableData;
