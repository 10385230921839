// action - state management
import {
  DELETE_INVOICE,
  SET_INVOICE_LIST,
  SET_SINGLE_INVOIVEDATA_DATA,
  FILTER_INVOICE,
  FILTER_INVOICE_DATA,
  SET_SPREADSHEET_DATA
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  invoiceList: [],
  singleInvoiceData: {},
  filterInvoice: {},
  filterdata: [],
  spreadsheetData: ''
};

const invoicesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_LIST: {
      return {
        ...state,
        invoiceList: [...state.invoiceList, ...action.payload],
        filterdata: [...state.invoiceList, ...action.payload]
      };
    }
    case DELETE_INVOICE: {
      return {
        ...state,
        filterdata: action.payload
      };
    }
    case SET_SINGLE_INVOIVEDATA_DATA: {
      return {
        ...state,
        singleInvoiceData: action.payload
      };
    }
    case FILTER_INVOICE: {
      return {
        ...state,
        filterInvoice: action.payload
      };
    }
    case FILTER_INVOICE_DATA: {
      return {
        ...state,
        filterdata: action.payload
      };
    }
    case SET_SPREADSHEET_DATA: {
      return {
        ...state,
        spreadsheetData: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default invoicesDataReducer;
