import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  Typography,
  InputBase,
  InputAdornment,
  OutlinedInput,
  Popper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import SelectSearch from 'react-select-search';
import Search from 'assets/images/dashboard/Search.svg';
import { shouldForwardProp } from '@mui/system';
import { fetchShipmentbySearchid } from 'containers/Shipments/action';
import { showWarnToaster } from 'ui-component/alertService';
import usePermission from 'hooks/usePermission';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions';
import { getCompanyByName } from 'containers/Company/action';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: '99%',
  top: '-55px !important',
  padding: '0 12px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px'
  }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
  width: 434,
  height: 40,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  '& input': {
    background: 'transparent !important',
    paddingLeft: '4px !important'
  },
  [theme.breakpoints.down('lg')]: {
    width: 250
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 4,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
  }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background:
    theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  color:
    theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
  '&:hover': {
    background:
      theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color:
      theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
  }
}));

const SearchBase = styled(InputBase)(({ theme }) => ({
  backgroundColor: 'rgb(236 239 241)',
  // width: ‘371px’,
  width: '100%',
  minWidth: '500px',
  padding: '6px 13px 6px 21px',
  borderRadius: '10px',
  [theme.breakpoints.down('lg')]: {
    width: '320px'
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const SelectSearchBox = styled(SelectSearch)(e => ({
  position: 'relative',
  '& div:nth-child(2)': {
    backgroundColor: 'white',
    boxShadow:
      e?.options?.length > 0 &&
      'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    position: 'absolute',
    left: 0,
    right: 0,
    borderRadius: '10px'
  }
}));

const toolbarSearchStyle = {
  background: 'rgb(236 239 241)',
  borderRadius: '10px',
  fontSize: '12px',
  marginLeft: 16,
  minWidth: 500,
  paddingLeft: 16,
  paddingRight: 16,
  height: 42
};

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <HeaderAvatarStyle variant="rounded">
              <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: '12px' }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.dark.main
                      : theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  '&:hover': {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light
                  }
                }}
                {...bindToggle(popupState)}>
                <IconX stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ 'aria-label': 'weight' }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = ({ userPermissions }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([{}]);
  const [closeOption, setCloseOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const userData = useSelector(state => state?.user?.userData);
  const [hasPermission] = usePermission();
  const userCompanyName = userData && userData['custom:company'];

  const handleShipmentDetail = id => {
    const shipmentDetail = searchOptions.filter(row => {
      return row?.shipment_id === id;
    })[0];
    const path = `/customer/shipment-details/${shipmentDetail.primaryKey}/${shipmentDetail.sortKey}`;
    history(path, { state: shipmentDetail });
    setSearchValue('');
    setCloseOption(false);

    dispatch({
      type: actionTypes.SET_SHIPMENT_DETAIL,
      payload: shipmentDetail
    });
  };

  const enterKeyPress = async e => {
    if (e.keyCode === 13 && searchValue) {
      setCloseOption(true);
      setIsLoading(true);
      setSearchOptions([{}]);
      const params = {
        search_id: searchValue,
        page: 0,
        rowsPerPage: 100
      };
      let company_data = null;
      if (!hasPermission('allShipments') || userCompanyName?.toLowerCase() !== 'shipx') {
        await getCompanyByName(userCompanyName)
          .then(async res => {
            company_data = res?.data[0];
          })
          .catch(() => {
            showWarnToaster('Company not found');
            return;
          });
        params.userCompanyId = company_data?.id;
      }
      fetchShipmentbySearchid(params, searchValue)
        .then(resp => {
          if (resp?.data) {
            setSearchOptions(resp?.data?.items);
            setIsLoading(false);
          } else {
            showWarnToaster('Shipment not found');
            setSearchOptions([{}]);
            setIsLoading(false);
          }
        })
        .catch(() => {
          showWarnToaster('Shipment not found');
          setSearchOptions([{}]);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {popupState => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                  <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: 'center left' }}>
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                          [theme.breakpoints.down('sm')]: {
                            border: 0,
                            boxShadow: 'none'
                          }
                        }}>
                        <Box sx={{ p: 2 }}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item xs>
                              <MobileSearch
                                value={value}
                                setValue={setValue}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box
        sx={{
          position: 'relative',
          paddingLeft: userPermissions?.role === 'SHIPX' ? '30px' : '10px'
        }}>
        <SelectSearchBox
          options={closeOption ? searchOptions : []}
          value=""
          debounce={500}
          closeOnSelect={true}
          placeholder={'Search'}
          search
          renderValue={valueProps => (
            <SearchBase
              {...valueProps}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyDown={e => enterKeyPress(e)}
              placeholder={'Search by order number'}
              fullWidth
              className={toolbarSearchStyle}
              startAdornment={
                <InputAdornment position="start">
                  <img src={Search} color={theme.palette.grey[400]} />
                </InputAdornment>
              }
              sx={{
                '& .MuiInputBase-input': {
                  color: '#000000',
                  fontSize: '16px',
                  '&::placeholder': {
                    color: '#000000',
                    fontSize: '16px'
                  }
                }
              }}
            />
          )}
          renderOption={(optionsProps, optionData) => (
            <Box>
              {isLoading ? (
                <Typography align="center">
                  <CircularProgress color="secondary" size="25px" />
                </Typography>
              ) : (
                <>
                  <Typography
                    align="left"
                    onClick={() => handleShipmentDetail(optionData?.shipment_id)}
                    variant="subtitle1"
                    sx={{ cursor: 'pointer' }}
                    color="#1f3bdf">
                    {optionData?.shipment_id}
                  </Typography>
                  <Typography align="left" variant="subtitle1" color="text.secondary">
                    {optionData?.delivery?.name}&nbsp;
                    {optionData?.delivery?.city_locality}&nbsp;
                    {optionData?.delivery?.postal_code}&nbsp;
                    {optionData?.delivery?.state_province}
                  </Typography>
                </>
              )}
            </Box>
          )}
        />
      </Box>
    </>
  );
};

export default SearchSection;
