const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,

  // this are admin cradential for performing cognito admin functionality
  // new development account cred
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  },
  userRoleKey: {
    OPERATIONS_ADMIN: 'OPERATIONS_ADMIN',
    ADMIN: 'ADMIN',
    CARRIER_ADMIN: 'CARRIER_ADMIN',
    CARRIER_ASSOCIATE: 'CARRIER_ASSOCIATE',
    CUSTOMER: 'CUSTOMER',
    ASSOCIATE: 'ASSOCIATE',
    SHIPPER_ADMIN: 'SHIPPER_ADMIN',
    SHIPPER_ASSOCIATE: 'SHIPPER_ASSOCIATE',
    // below groups are not listed on cognito user pool
    SHIPX: 'SHIPX', // This is assigned to user whith company name ShipX
    CUSTOMER_ASSOCIATE: 'CUSTOMER_ASSOCIATE',
    CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
    ADD_COMPANY_DETAILS: 'ADD_COMPANY_DETAILS' // This group is assigned to user with uncompleted profile
  },
  cognitoUserGroupName: {
    OPERATIONS_ADMIN: 'operations-admin',
    ADMIN: 'admin',
    CARRIER_ADMIN: 'carrier-admin',
    CARRIER_ASSOCIATE: 'carrier-associate',
    CUSTOMER: 'customer-service',
    ASSOCIATE: 'operations-associate',
    SHIPPER_ADMIN: 'shipper-admin',
    SHIPPER_ASSOCIATE: 'shipper-associate',
    // below groups are not listed on cognito user pool
    SHIPX: 'SHIPX', // This is assigned to user whith company name ShipX
    CUSTOMER_ASSOCIATE: 'customer-associate',
    CUSTOMER_ADMIN: 'customer-admin',
    ADD_COMPANY_DETAILS: 'add-company-details' // This group is assigned to user with uncompleted profile
  },
  defaultRouteForUserRole: {
    OPERATIONS_ADMIN: '/dashboard/default',
    ADMIN: '/dashboard/default',
    CARRIER_ADMIN: '/dashboard/default',
    CARRIER_ASSOCIATE: '/dashboard/default',
    CUSTOMER: '/dashboard/default',
    ASSOCIATE: '/dashboard/default',
    SHIPPER_ADMIN: '/dashboard/default',
    SHIPPER_ASSOCIATE: '/dashboard/default',
    // below groups are not listed on cognito user pool
    SHIPX: 'SHIPX', // This is assigned to user whith company name ShipX
    DEFAULT: '/dashboard/default',
    CUSTOMER_ASSOCIATE: '/dashboard/default',
    CUSTOMER_ADMIN: '/dashboard/default',
    ADD_COMPANY_DETAILS: '/customer/company' // This group is assigned to user with uncompleted profile
  },
  ERROR_PATH: '/pages/error',
  basename: '/',
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  SES_FROM_MAIL: process.env.REACT_APP_SES_FROM_EMAIL,
  xApiKey: process.env.REACT_APP_X_API_KEY,
  googleMapUrl: process.env.REACT_APP_GOOGLE_MAP_BASE_URL,
  googleMapKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  mapBoxKey: process.env.REACT_APP_MAPBOX_API_KEY,
  mapBoxUseName: process.env.REACT_APP_MAPBOX_USER_NAME,
  graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  defaultPath: '/operations/sort',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  theme: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days'
  },
  auth0: {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
  }
};

export default config;
