// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const ISNEWUSER = 'ISNEWUSER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const USER_GROUP = 'USER_GROUP';
export const USER_COMPANY = 'USER_COMPANY';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_WAREHOUSE_LIST = 'SET_WAREHOUSE_LIST';
export const USERROLE = 'USERROLE';
export const UPDATE_FILL_COMPANYDETAIL_BOOLEAN_VALUE = 'UPDATE_FILL_COMPANYDETAIL_BOOLEAN_VALUE';
export const IS_ADMIN_CHANGE_COMPANY = 'IS_ADMIN_CHANGE_COMPANY';

export const SET_DASHBOARD_SHIPMENT_LIST = 'SET_DASHBOARD_SHIPMENT_LIST';
export const CLEAR_DASHBOARD_SHIPMENT_LIST = 'CLEAR_DASHBOARD_SHIPMENT_LIST';
export const SET_DASHBOARD_SHIPMENT_LIST_PAGE_KEYS = 'SET_DASHBOARD_SHIPMENT_LIST_PAGE_KEYS';
export const SET_DASHBOARD_SHIPMENT_LIST_PAGE_NUMBER = 'SET_DASHBOARD_SHIPMENT_LIST_PAGE_NUMBER';
export const SET_DASHBOARD_SHIPMENT_LIST_LAST_PAGE = 'SET_DASHBOARD_SHIPMENT_LIST_LAST_PAGE';
export const SET_DASHBOARD_SHIPMENT_LIST_PARAMS = 'SET_DASHBOARD_SHIPMENT_LIST_PARAMS';
export const SET_DASHBOARD_SHIPMENT_LIST_ROW_LENGTH = 'SET_DASHBOARD_SHIPMENT_LIST_ROW_LENGTH';
export const SET_DASHBOARD_SHIPMENT_LIST_START_AND_END_DATE =
  'SET_DASHBOARD_SHIPMENT_LIST_START_AND_END_DATE';
export const SET_DASHBOARD_SHIPMENT_LIST_WITH_VOIDED_RECORD =
  'SET_DASHBOARD_SHIPMENT_LIST_WITH_VOIDED_RECORD';
export const SET_DASHBOARD_SHIPMENT_LIST_VIEW_TYPE = 'SET_DASHBOARD_SHIPMENT_LIST_VIEW_TYPE';
export const SET_DASHBOARD_SELECT_SHIPMENT_DATA = 'SET_DASHBOARD_SELECT_SHIPMENT_DATA';

export const SET_SHIPMENT_LIST = 'SET_SHIPMENT_LIST';
export const SET_SHIPMENT_ROW = 'SET_SHIPMENT_ROW';
export const SET_SHIPMENT_DETAIL = 'SET_SHIPMENT_DETAIL';
export const CLEAR_SHIPMENT_LIST = 'CLEAR_SHIPMENT_LIST';
export const SET_SHIPMENT_LIST_WITH_VOIDED_RECORD = 'SET_SHIPMENT_LIST_WITH_VOIDED_RECORD';
export const SET_SHIPMENT_LIST_ROW_LENGTH = 'SET_SHIPMENT_LIST_ROW_LENGTH';
export const SET_SHIPMENT_LIST_PAGE_NUMBER = 'SET_SHIPMENT_LIST_PAGE_NUMBER';
export const SET_SHIPMENT_LIST_LAST_PAGE = 'SET_SHIPMENT_LIST_LAST_PAGE';
export const SET_SHIPMENT_LIST_PAGE_KEYS = 'SET_SHIPMENT_LIST_PAGE_KEYS';
export const SET_SHIPMENT_LIST_PARAMS = 'SET_SHIPMENT_LIST_PARAMS';
export const SET_SHIPMENT_LIST_START_AND_END_DATE = 'SET_SHIPMENT_LIST_START_AND_END_DATE';

export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const CLEAR_CONTACT_LIST = 'CLEAR_CONTACT_LIST';
export const SET_CONTACT_LIST_ROW_LENGTH = 'SET_CONTACT_LIST_ROW_LENGTH';
export const SET_CONTACT_LIST_PAGE_NUMBER = 'SET_CONTACT_LIST_PAGE_NUMBER';
export const SET_CONTACT_LIST_LAST_PAGE = 'SET_CONTACT_LIST_LAST_PAGE';
export const SET_CONTACT_LIST_PAGE_KEYS = 'SET_CONTACT_LIST_PAGE_KEYS';
export const SET_CONTACT_LIST_PARAMS = 'SET_CONTACT_LIST_PARAMS';

export const SET_USER_LIST = 'SET_USER_LIST';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const SET_USER_LIST_UPDATED_USER = 'SET_USER_LIST_UPDATED_USER';
export const SET_USER_LIST_CREATED_USER = 'SET_USER_LIST_CREATED_USER';
export const SET_USER_LIST_TOKEN = 'SET_USER_LIST_TOKEN';

export const SET_FILTER = 'SET_FILTER';
export const SET_REPORT_SHIPMENTS = 'SET_REPORT_SHIPMENTS';
export const REPORT_UPDATED = 'REPORT_UPDATED';
export const CONFIRM_NAVIGATION = 'CONFIRM_NAVIGATION';

export const SET_CHIP_SUMMARY = 'SET_CHIP_SUMMARY';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// loader
export const LOADER = 'LOADER';

// action - cart
export const ADD_PRODUCTS = '@cart/ADD_PRODUCTS';
export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const UPDATE_QUANTITY = '@cart/UPDATE_QUANTITY';
export const NEXT_STEP = '@cart/NEXT_STEP';
export const BACK_STEP = '@cart/BACK_STEP';
export const SET_STEP = '@cart/SET_STEP';
export const SET_BILLING_ADDRESS = '@cart/SET_BILLING_ADDRESS';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';
export const SET_SHIPPING_CHARGE = '@cart/SET_SHIPPING_CHARGE';
export const SET_PAYMENT_METHOD = '@cart/SET_PAYMENT_METHOD';
export const SET_PAYMENT_CARD = '@cart/SET_PAYMENT_CARD';
export const RESET_CART = '@cart/RESET_CART';

// action - kanban
export const ADD_COLUMN = '@kanban/ADD_COLUMN';
export const EDIT_COLUMN = '@kanban/EDIT_COLUMN';
export const DELETE_COLUMN = '@kanban/DELETE_COLUMN';
export const UPDATE_COLUMN_ORDER = '@kanban/UPDATE_COLUMN_ORDER';

export const ADD_ITEM = '@kanban/ADD_ITEM';
export const EDIT_ITEM = '@kanban/EDIT_ITEM';
export const DELETE_ITEM = '@kanban/DELETE_ITEM';
export const UPDATE_COLUMN_ITEM_ORDER = '@kanban/UPDATE_COLUMN_ITEM_ORDER';
export const SELECT_ITEM = '@kanban/SELECT_ITEM';
export const ADD_ITEM_COMMENT = '@kanban/ADD_ITEM_COMMENT';

export const UPDATE_STORY_ORDER = '@kanban/UPDATE_STORY_ORDER';
export const UPDATE_STORY_ITEM_ORDER = '@kanban/UPDATE_STORY_ITEM_ORDER';
export const DELETE_STORY = '@kanban/DELETE_STORY';
export const ADD_STORY = '@kanban/ADD_STORY';
export const EDIT_STORY = '@kanban/EDIT_STORY';
export const ADD_STORY_COMMENT = '@kanban/ADD_STORY_COMMENT';

// customer list
export const LIST_CUSTOMER = '@customer/LIST_CUSTOMER';
export const LIST_WAREHOUSE = '@customer/LIST_WAREHOUSE';
export const SET_CUSTOMER = '@customer/SET_CUSTOMER';
export const REMOVE_CUSTOMER = '@customer/REMOVE_CUSTOMER';
export const UPDATE_CUSTOMER = '@customer/UPDATE_CUSTOMER';
export const UPDATE_WAREHOUSE = '@customer/UPDATE_WAREHOUSE';
export const DELETE_WAREHOUSE = '@customer/DELETE_WAREHOUSE';
export const DELETE_CUSTOMER = '@customer/DELETE_CUSTOMER';
export const ADD_CUSTOMER = '@customer/ADD_CUSTOMER';
export const ADD_WAREHOUSE = '@customer/ADD_WAREHOUSE';
export const SET_WAREHOUSELEVEL = '@customer/SET_WAREHOUSELEVEL';
export const SET_ZONELEVEL = '@customer/SET_ZONELEVEL';

// warehouse option selected
export const SET_WAREHOUSEOPTION = '@customer/SET_WAREHOUSEOPTION';

// add company details
export const ADD_COMPANY_DETAILS = '@customer/ADD_COMPANY_DETAILS';
// add company map data
export const ADD_COMPANY_MAPDATA = '@customer/ADD_COMPANY_MAPDATA';
// add company logo
export const ADD_COMPANY_LOGO = '@customer/ADD_COMPANY_LOGO';

// base price
export const SET_BASEPRICEVALUE = '@customer/SET_BASEPRICEVALUE';

// zone value
export const SET_ZONEVALUE = '@customer/SET_ZONEVALUE';

// company id data value
export const SET_COMIDVALUE = '@customer/SET_COMIDVALUE';

//reset store
export const RESET_STORE = '@customer/RESET_STORE';

// invoice get list

export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_SINGLE_INVOIVEDATA_DATA = 'SET_SINGLE_INVOIVEDATA_DATA';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const FILTER_INVOICE = 'FILTER_INVOICE';
export const FILTER_INVOICE_DATA = 'FILTER_INVOICE_DATA';
export const SET_SPREADSHEET_DATA = 'SET_SPREADSHEET_DATA';
